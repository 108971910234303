import "../scss/home-page.scss";

import * as Cookies from "js-cookie";
import { homePageHospitalSet, showHospitalDetector } from "./modules/hospitalDetector";

const hospitalCookie = Cookies.get("hospital_id");

if (hospitalCookie) {
    homePageHospitalSet(hospitalCookie);
} else {
    const hospitalButton = document.querySelector<HTMLElement>(".home-hospital-button button");
    hospitalButton?.addEventListener("click", showHospitalDetector);
}
